import axios from '@/common/axios'
import qs from 'qs'

export function addSatisfactionSurvey (data) {
  return axios({
    method: 'post',
    url: '/sale/survey/add',
    data: qs.stringify(data)
  })
}

export function deleteSatisfactionSurvey (id) {
  return axios({
    method: 'delete',
    url: '/sale/survey/delete/' + id
  })
}

export function updateSatisfactionSurvey (data) {
  return axios({
    method: 'put',
    url: '/sale/survey/update',
    data: qs.stringify(data)
  })
}

export function selectSatisfactionSurveyInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/survey/info/' + id
  })
}

export function selectSatisfactionSurveyList (query) {
  return axios({
    method: 'get',
    url: '/sale/survey/list',
    params: query
  })
}
