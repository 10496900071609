<template>
  <div id="satisfactionSurvey">
    <el-dialog
      :title="satisfactionSurveyFormTitle"
      width="1080px"
      :visible.sync="satisfactionSurveyDialogVisible"
      :close-on-click-modal="false"
      @close="satisfactionSurveyDialogClose"
    >
      <el-form
        ref="satisfactionSurveyFormRef"
        :model="satisfactionSurveyForm"
        :rules="satisfactionSurveyFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户名称" prop="customerId">
              <el-select
                v-model="satisfactionSurveyForm.customerId"
                placeholder="请选择客户"
                clearable
                @change="customerChange"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.customerName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人/联系方式" prop="contactPerson">
              <el-input v-model="satisfactionSurveyForm.contactPerson" placeholder="请输入联系人/联系方式" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="填表日期" prop="fillDate">
              <el-date-picker v-model="satisfactionSurveyForm.fillDate" placeholder="请选择填表日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="对本公司服务人员素质、态度、技术评价" prop="survey1" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey1">
            <el-radio label="1">
              满意
            </el-radio>
            <el-radio label="2">
              一般
            </el-radio>
            <el-radio label="3">
              不满意
            </el-radio>
            <el-radio label="4">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对本公司产品运输过程评价" prop="survey2" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey2">
            <el-radio label="1">
              满意
            </el-radio>
            <el-radio label="2">
              一般
            </el-radio>
            <el-radio label="3">
              不满意
            </el-radio>
            <el-radio label="4">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对本公司产品包装的满意程度" prop="survey3" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey3">
            <el-radio label="1">
              满意
            </el-radio>
            <el-radio label="2">
              一般
            </el-radio>
            <el-radio label="3">
              不满意
            </el-radio>
            <el-radio label="4">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对本公司产品质量的满意程度" prop="survey4" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey4">
            <el-radio label="1">
              满意
            </el-radio>
            <el-radio label="2">
              一般
            </el-radio>
            <el-radio label="3">
              不满意
            </el-radio>
            <el-radio label="4">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对本公司产品销售的满意程度" prop="survey5" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey5">
            <el-radio label="1">
              满意
            </el-radio>
            <el-radio label="2">
              一般
            </el-radio>
            <el-radio label="3">
              不满意
            </el-radio>
            <el-radio label="4">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对本公司产品售后服务满意程度" prop="survey6" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey6">
            <el-radio label="1">
              满意
            </el-radio>
            <el-radio label="2">
              一般
            </el-radio>
            <el-radio label="3">
              不满意
            </el-radio>
            <el-radio label="4">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="与同类型产品相比，对本公司产品的性价比的满意程度" prop="survey7" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey7">
            <el-radio label="1">
              满意
            </el-radio>
            <el-radio label="2">
              一般
            </el-radio>
            <el-radio label="3">
              不满意
            </el-radio>
            <el-radio label="4">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否愿意再次购买本公司产品" prop="survey8" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey8">
            <el-radio label="1">
              愿意
            </el-radio>
            <el-radio label="2">
              看情况
            </el-radio>
            <el-radio label="3">
              不愿意
            </el-radio>
            <el-radio label="4">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择购买本公司产品的理由" prop="survey9" label-width="360px">
          <el-radio-group v-model="satisfactionSurveyForm.survey9">
            <el-radio label="1">
              产品质量
            </el-radio>
            <el-radio label="2">
              性价比
            </el-radio>
            <el-radio label="3">
              人员服务质量
            </el-radio>
            <el-radio label="4">
              品牌影响力
            </el-radio>
            <el-radio label="5">
              供货及时性
            </el-radio>
            <el-radio label="6">
              其他
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对本公司的建议" prop="suggest">
          <el-input
            v-model="satisfactionSurveyForm.suggest"
            placeholder="请输入对本公司的建议"
            type="textarea"
            :rows="4"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="satisfactionSurveyDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="satisfactionSurveyFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="客户名称">
        <el-input v-model="searchForm.customerName" placeholder="请输入客户名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="satisfactionSurveyPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="contactPerson" label="联系人/联系方式" />
      <el-table-column label="填表日期">
        <template slot-scope="scope">
          <span v-if="scope.row.fillDate">{{ scope.row.fillDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="对本公司服务人员素质、态度、技术评价">
        <template slot-scope="scope">
          <span v-if="scope.row.survey1 === '1'">满意</span>
          <span v-if="scope.row.survey1 === '2'">一般</span>
          <span v-if="scope.row.survey1 === '3'">不满意</span>
          <span v-if="scope.row.survey1 === '4'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="对本公司产品运输过程评价">
        <template slot-scope="scope">
          <span v-if="scope.row.survey2 === '1'">满意</span>
          <span v-if="scope.row.survey2 === '2'">一般</span>
          <span v-if="scope.row.survey2 === '3'">不满意</span>
          <span v-if="scope.row.survey2 === '4'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="对本公司产品包装的满意程度">
        <template slot-scope="scope">
          <span v-if="scope.row.survey3 === '1'">满意</span>
          <span v-if="scope.row.survey3 === '2'">一般</span>
          <span v-if="scope.row.survey3 === '3'">不满意</span>
          <span v-if="scope.row.survey3 === '4'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="对本公司产品质量的满意程度">
        <template slot-scope="scope">
          <span v-if="scope.row.survey4 === '1'">满意</span>
          <span v-if="scope.row.survey4 === '2'">一般</span>
          <span v-if="scope.row.survey4 === '3'">不满意</span>
          <span v-if="scope.row.survey4 === '4'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="对本公司产品销售的满意程度">
        <template slot-scope="scope">
          <span v-if="scope.row.survey5 === '1'">满意</span>
          <span v-if="scope.row.survey5 === '2'">一般</span>
          <span v-if="scope.row.survey5 === '3'">不满意</span>
          <span v-if="scope.row.survey5 === '4'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="对本公司产品售后服务满意程度">
        <template slot-scope="scope">
          <span v-if="scope.row.survey6 === '1'">满意</span>
          <span v-if="scope.row.survey6 === '2'">一般</span>
          <span v-if="scope.row.survey6 === '3'">不满意</span>
          <span v-if="scope.row.survey6 === '4'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="与同类型产品相比，对本公司产品的性价比的满意程度">
        <template slot-scope="scope">
          <span v-if="scope.row.survey7 === '1'">满意</span>
          <span v-if="scope.row.survey7 === '2'">一般</span>
          <span v-if="scope.row.survey7 === '3'">不满意</span>
          <span v-if="scope.row.survey7 === '4'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="是否愿意再次购买本公司产品">
        <template slot-scope="scope">
          <span v-if="scope.row.survey8 === '1'">愿意</span>
          <span v-if="scope.row.survey8 === '2'">看情况</span>
          <span v-if="scope.row.survey8 === '3'">不愿意</span>
          <span v-if="scope.row.survey8 === '4'">其他</span>
        </template>
      </el-table-column>
      <el-table-column label="选择购买本公司产品的理由">
        <template slot-scope="scope">
          <span v-if="scope.row.survey9 === '1'">产品质量</span>
          <span v-if="scope.row.survey9 === '2'">性价比</span>
          <span v-if="scope.row.survey9 === '3'">人员服务质量</span>
          <span v-if="scope.row.survey9 === '4'">品牌影响力</span>
          <span v-if="scope.row.survey9 === '5'">供货及时性</span>
          <span v-if="scope.row.survey9 === '6'">其他</span>
        </template>
      </el-table-column>
      <el-table-column prop="suggest" label="对本公司的建议" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="satisfactionSurveyPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSatisfactionSurvey,
  deleteSatisfactionSurvey,
  updateSatisfactionSurvey,
  selectSatisfactionSurveyInfo,
  selectSatisfactionSurveyList
} from '@/api/sale/satisfactionSurvey'
import { selectCustomerList } from '@/api/sale/customer'

export default {
  data () {
    return {
      satisfactionSurveyDialogVisible: false,
      satisfactionSurveyFormTitle: '',
      satisfactionSurveyForm: {
        id: '',
        customerId: '',
        customerName: '',
        contactPerson: '',
        fillDate: '',
        survey1: '',
        survey2: '',
        survey3: '',
        survey4: '',
        survey5: '',
        survey6: '',
        survey7: '',
        survey8: '',
        survey9: '',
        suggest: ''
      },
      satisfactionSurveyFormRules: {
        customerId: [{ required: true, message: '客户名称不能为空', trigger: ['blur', 'change']}]
      },
      satisfactionSurveyPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        customerName: ''
      },
      customerList: []
    }
  },
  created () {
    selectCustomerList().then(res => {
      this.customerList = res.list
    })
    selectSatisfactionSurveyList(this.searchForm).then(res => {
      this.satisfactionSurveyPage = res
    })
  },
  methods: {
    satisfactionSurveyDialogClose () {
      this.$refs.satisfactionSurveyFormRef.resetFields()
    },
    satisfactionSurveyFormSubmit () {
      if (this.satisfactionSurveyFormTitle === '顾客满意度调查详情') {
        this.satisfactionSurveyDialogVisible = false
        return
      }
      this.$refs.satisfactionSurveyFormRef.validate(async valid => {
        if (valid) {
          if (this.satisfactionSurveyFormTitle === '新增顾客满意度调查') {
            this.satisfactionSurveyForm.id = ''
            await addSatisfactionSurvey(this.satisfactionSurveyForm)
          } else if (this.satisfactionSurveyFormTitle === '修改顾客满意度调查') {
            await updateSatisfactionSurvey(this.satisfactionSurveyForm)
          }
          this.satisfactionSurveyPage = await selectSatisfactionSurveyList(this.searchForm)
          this.satisfactionSurveyDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.satisfactionSurveyFormTitle = '新增顾客满意度调查'
      this.satisfactionSurveyDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSatisfactionSurvey(row.id)
        if (this.satisfactionSurveyPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.satisfactionSurveyPage = await selectSatisfactionSurveyList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.satisfactionSurveyFormTitle = '修改顾客满意度调查'
      this.satisfactionSurveyDialogVisible = true
      this.selectSatisfactionSurveyInfoById(row.id)
    },
    handleInfo (index, row) {
      this.satisfactionSurveyFormTitle = '顾客满意度调查详情'
      this.satisfactionSurveyDialogVisible = true
      this.selectSatisfactionSurveyInfoById(row.id)
    },
    selectSatisfactionSurveyInfoById (id) {
      selectSatisfactionSurveyInfo(id).then(res => {
        this.satisfactionSurveyForm.id = res.id
        this.satisfactionSurveyForm.customerId = res.customerId
        this.satisfactionSurveyForm.customerName = res.customerName
        this.satisfactionSurveyForm.contactPerson = res.contactPerson
        this.satisfactionSurveyForm.fillDate = res.fillDate
        this.satisfactionSurveyForm.survey1 = res.survey1
        this.satisfactionSurveyForm.survey2 = res.survey2
        this.satisfactionSurveyForm.survey3 = res.survey3
        this.satisfactionSurveyForm.survey4 = res.survey4
        this.satisfactionSurveyForm.survey5 = res.survey5
        this.satisfactionSurveyForm.survey6 = res.survey6
        this.satisfactionSurveyForm.survey7 = res.survey7
        this.satisfactionSurveyForm.survey8 = res.survey8
        this.satisfactionSurveyForm.survey9 = res.survey9
        this.satisfactionSurveyForm.suggest = res.suggest
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSatisfactionSurveyList(this.searchForm).then(res => {
        this.satisfactionSurveyPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSatisfactionSurveyList(this.searchForm).then(res => {
        this.satisfactionSurveyPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSatisfactionSurveyList(this.searchForm).then(res => {
        this.satisfactionSurveyPage = res
      })
    },
    customerChange (value) {
      if (value) {
        let customer = this.customerList.find(item => item.id === value)
        this.satisfactionSurveyForm.customerName = customer.customerName
        this.satisfactionSurveyForm.contactPerson = customer.contactPerson + '/' + customer.phone
      } else {
        this.satisfactionSurveyForm.customerName = ''
        this.satisfactionSurveyForm.contactPerson = ''
      }
    }
  }
}
</script>

<style>
</style>
